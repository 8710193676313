import { useEffect, Fragment, useState } from 'react';
import { useParams } from 'react-router';
import { CssBaseline, Box, Typography, Container } from '@mui/material';
import data from '../data.json';
import showdown from 'showdown';
import RenderReport from './RenderReport';
import axios from 'axios';
import { createPlainReport, save_report_to_database, split_string_by } from '../utils';

const { REACT_APP_DEV_AI_API_URL, REACT_APP_NIMANI_URL } = process.env;

export default function SearchPage() {
  const report_type = localStorage.getItem('report_type') || '';
  const task = localStorage.getItem('task') || '';
  const datasets: any = localStorage.getItem('datasets' || []);
  const [status, setStatus] = useState<string>('initial');
  const [output, setOutput] = useState<any>([]);
  const [report, setReport] = useState<any>([]);
  const [savedReport, setSavedReport] = useState<[]>([]);
  const [readyToCopy, setReadyToCopy] = useState<any>([]);
  const params = useParams();

  const protocol = 'http:';
  const ws_uri = `${protocol === 'http:' ? 'wss:' : 'ws:'}//${REACT_APP_NIMANI_URL}/api/research/ws`;

  const fetchReportFromDatabase = async () => {
    await axios({
      method: 'get',
      url: `${REACT_APP_DEV_AI_API_URL}/api/conversation/${params.conversation_id}`
    })
      .catch((error: any) => {
        console.log(error);
      })
      .then((response: any) => {
        if (response.data) {
          setSavedReport(response.data.report_text);
          const rep = split_string_by(response.data.report_text.toString());
          setReadyToCopy(createPlainReport(rep).join('\n\n###'));
          setStatus('finished');
        } else {
          const socket = new WebSocket(ws_uri);
          const converter = new showdown.Converter();
          socket.onopen = (event) => {
            const requestData = {
              task: task,
              datasets: JSON.parse(datasets),
              report_type: report_type,
              agent: 'Auto Agent',
              conversation_id: params.conversation_id
            };

            socket.send(`start ${JSON.stringify(requestData)}`);
          };

          let renderWhileLoading: any[] = [];
          socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            window.scrollTo(0, document.body.scrollHeight);
            if (data.type === 'logs') {
              setOutput((prevMessages: any) => [...prevMessages, data.output]);
            } else if (data.type === 'report') {
              setReadyToCopy((prevMessages: any) => [...prevMessages, data.output]);
              const markdownOutput = converter.makeHtml(data.output);
              setReport((prevMessages: any) => [...prevMessages, markdownOutput]);
              renderWhileLoading = [...renderWhileLoading, markdownOutput];
            } else if (data.type === 'end') {
              save_report_to_database(renderWhileLoading, params.conversation_id,task);
              setStatus('finished');
            }
          };

          return () => {
            socket.close();
          };
        }
      });
  };

  useEffect(() => {
    setStatus('in_progress');
    fetchReportFromDatabase();
  }, []);

  const savedReportSplit = split_string_by(savedReport.toString());

  const reportToRender = savedReport && savedReport.length > 0 ? savedReportSplit : report;

  return (
    <Fragment>
      <CssBaseline />
      <Container id="search-container">
        <Box>
          <h1>Agent Output</h1>
          <Typography>
            An agent tailored specifically to your task will be generated to provide the most
            precise and relevant research results.
          </Typography>
        </Box>
        <Box sx={{ mt: 5 }}>
          {task && (
            <Typography sx={{ pb: 2 }}>
              <b>Search term:</b> {task}
            </Typography>
          )}
          {data &&
            data['report_type']
              .filter((dt) => dt.name === report_type)
              .map((dt) => (
                <Typography key={dt.name}>
                  <b>Report Type:</b> {dt.value}
                </Typography>
              ))}
        </Box>
        {status !== 'finished' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
            <div id="log-warp">
            {output.map((out: any, index: any) => (
              <p key={index}>{out}</p>
            ))}
            </div>
          </Box>
        )}
        {reportToRender && (
          <Box id="report-container" sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
            <h1>Research Report</h1>
            <RenderReport
              report={reportToRender}
              status={status}
              readyToCopy={readyToCopy}
              conversationID={params.conversation_id}
              question={task}
            />
          </Box>
        )}
      </Container>
    </Fragment>
  );
}
