import { useState, ComponentType, ReactElement, useEffect } from 'react';
import axios from 'axios';
import { Box, Snackbar, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TransitionProps } from '@mui/material/transitions';
import Slide, { SlideProps } from '@mui/material/Slide';
import Fade from '@mui/material/Fade';

const { REACT_APP_DEV_AI_API_URL } = process.env;

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}
export default function ActionButtons(props: { report: any; conversationID: string }) {
  const [wordDocument, generateWordDocument] = useState<string>('');
  const [pdfDocument, generatePDFDocument] = useState<string>('');
  const [state, setState] = useState<{
    open: boolean;
    Transition: ComponentType<
      TransitionProps & {
        children: ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade
  });

  const generateWord = async () => {
    await axios({
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
      url: `${REACT_APP_DEV_AI_API_URL}/api/conversation/export-to-word/${props.conversationID}`
    })
      .catch((error: any) => {
        console.log(error);
      })
      .then((response: any) => {
        generateWordDocument(response.data);
      });
  };

  const generatePDF = async () => {
    await axios({
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
      url: `${REACT_APP_DEV_AI_API_URL}/api/conversation/export-to-pdf/${props.conversationID}`
    })
      .catch((error: any) => {
        console.log(error);
      })
      .then((response: any) => {
        generatePDFDocument(response.data);
      });
  };

  useEffect(() => {
    generateWord();
    generatePDF();
  }, []);

  const handleClick =
    (
      Transition: ComponentType<
        TransitionProps & {
          children: ReactElement<any, any>;
        }
      >
    ) =>
    () => {
      setState({
        open: true,
        Transition
      });
    };

  const handleClose = () => {
    setState({
      ...state,
      open: false
    });
  };

  const downloadWord: any = `${REACT_APP_DEV_AI_API_URL}/${wordDocument}`;

  const downloadPDF: any = `${REACT_APP_DEV_AI_API_URL}/${pdfDocument}`;

  return (
    <Box>
      <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          Copied!
        </Alert>
      </Snackbar>
      <Box className="alert alert-info" role="alert" id="status"></Box>
      <CopyToClipboard text={props.report}>
        <Link
          id="copyToClipboard"
          to="#"
          onClick={handleClick(SlideTransition)}
          className="btn btn-secondary mt-3"
        >
          Copy to clipboard
        </Link>
      </CopyToClipboard>
      <Link id="downloadLink" to={downloadPDF} className="btn btn-secondary" target="_blank">
        Download as PDF
      </Link>
      <Link id="downloadLinkWord" to={downloadWord} className="btn btn-secondary" target="_blank">
        Download as WORD
      </Link>
    </Box>
  );
}
