import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Box, Button } from '@mui/material';

function AdjustOutput(props: any) {
  const [text, setText] = useState(props.text);

  const clearText = () => {
    setText('');
  };

  return (
    <Box>
      <TextareaAutosize
        cacheMeasurements
        value={text}
        onChange={(ev) => setText(ev.target.value)}
      />
      <Box sx={{ mt: 3 }}>
        <Button
          sx={{ marginRight: '25px !important' }}
          onClick={clearText}
          className="btn btn-secondary"
        >
          Clear All
        </Button>
        <Button
          onClick={() => props.saveManualAdjustment(text)}
          sx={{ marginRight: '25px !important' }}
          className="btn btn-secondary"
        >
          Save Changes
        </Button>
        <Button onClick={() => props.stateChanger(false)} className="btn btn-secondary">
          Cancel
        </Button>
      </Box>
    </Box>
  );
}

export default AdjustOutput;
