import { Alert, Box } from '@mui/material';

export default function ProgressBox(props: any) {
  let infoBox: any;
  switch (props.status) {
    case 'initial':
      infoBox = <Alert severity="info">Initialization</Alert>;
      break;
    case 'in_progress':
      infoBox = (
        <Alert sx={{ mt: 5, mb: 20 }} severity="info">
          Research in progress, please wait...
        </Alert>
      );
      break;
    case 'finished':
      infoBox = <Alert sx={{ mt: 5, mb: 10 }}>Research finished!</Alert>;
  }
  return <Box>{infoBox}</Box>;
}
