import React, { createRef } from 'react';
import { Box, Popper, Fade, Paper } from '@mui/material';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import {
  createPlainReport,
  remove_anchor_tags,
  remove_html_tags,
  save_report_to_database
} from '../utils';
import parse from 'html-react-parser';
import ProgressBox from './ProgressBox';
import ActionButtons from './ActionButtons';
import DetailPromptDialog from './DetailPromptDialog';

class RenderReport extends React.Component {
  constructor(props) {
    super(props);
    this.fullText = createRef();
    this.div = createRef();
    this.state = {
      openPopper: false,
      anchorEl: null,
      contextMenu: null,
      selectionStart: null,
      selectionEnd: null,
      exact_match: null,
      position: {},
      openPrompt: false,
      replacedString: '',
      acceptedPrompts: [],
      savedReport: [],
    };
  }

  openPromptForm = () => {
    this.setState({ openPrompt: !this.state.openPrompt });
  };

  acceptPrompt = async (exact_match, new_text, prompt) => {
    let content = this.state.replacedString !== '' ? this.state.replacedString : this.props.report;
    exact_match = exact_match.replace('\r', '').replace('\n', '').trim();

    // if <a href='some_link'> text link</a> link exists in text, remove a tags and leave text link
    let contentCopy = content.map((t) => remove_anchor_tags(t));
    let index = contentCopy.findIndex((obj) => remove_html_tags(obj, false) === exact_match);
    if (index === -1) {
      // Handle case where item is not found
      return;
    }

    const toBeReplaced = [
      ...content.slice(0, index), // all items before the found item
      new_text, // the new item to replace the old one
      ...content.slice(index + 1) // all items after the found item
    ];

    this.setState({
      replacedString: toBeReplaced
    });

    // replace in database by conversation_id
    save_report_to_database(toBeReplaced, this.props.conversationID, this.props.question);

    let plainReport = [];
    this.props.report.map((rep) => plainReport.push(rep));

    this.setState({
      acceptedPrompts: [
        ...this.state.acceptedPrompts,
        {
          conversation_id: this.props.conversationID,
          start: this.state.selectionStart,
          end: this.state.selectionEnd,
          highlight: this.state.exact_match.trim(),
          comment: prompt,
          ai_text: new_text,
          whole_text: plainReport.toString()
        }
      ]
    });
    this.openPromptForm();
  };

  handleClose = () => {
    this.setState({ openPopper: false });
  };

  isSelection() {
    const selection = window.getSelection();
    if (!selection || selection.anchorOffset === selection.focusOffset) {
      return;
    }
    return !!selection.toString().trim();
  }

  findStartIndex = (text, index) => {
    while (index > 0 && text[index] !== '\n') {
      index--;
    }
    return index;
  };

  findEndIndex = (text, index) => {
    while (index < text.length && text[index] !== '\n') {
      index++;
    }
    return index;
  };

  handleSelect = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString();
    const textLength = selectedText.length;

    let characterStart = this.fullText.current.innerText.indexOf(selectedText);
    characterStart = this.findStartIndex(this.fullText.current.innerText, characterStart);

    const characterEnd = this.findEndIndex(
      this.fullText.current.innerText,
      characterStart + textLength
    );

    if (!selection || selection.anchorOffset === selection.focusOffset) {
      this.handleClose();
      return;
    }

    if (!this.isSelection()) return;

    this.setState({ selectionStart: characterStart });
    this.setState({ selectionEnd: characterEnd });

    const rect = selection.getRangeAt(0).getBoundingClientRect();
    this.setState({
      position: {
        x: rect.left + rect.width / 2 - 80 / 2,
        y: rect.top + window.scrollY - 30,
        width: rect.width,
        height: rect.height
      }
    });

    this.setState({ anchorEl: rect });
    this.setState({ openPopper: true });
    this.setState({
      exact_match: this.fullText.current.innerText.slice(characterStart, characterEnd)
    });
  };

  render() {
    const id = this.state.openPopper ? 'virtual-element-popper' : undefined;
    const reportToRender =
      this.state.replacedString !== '' ? this.state.replacedString : this.props.report;

    let plainReport = createPlainReport(this.props.report);
    return (
      <Box onMouseLeave={this.handleClose}>
        <Box
          onMouseUp={this.handleSelect}
          onChange={this.handleSelect}
          ref={this.fullText}
          aria-describedby={id}
        >
          {reportToRender &&
            reportToRender.map((rep, index) => <Box key={index}>{parse(rep)}</Box>)}
        </Box>
        <Box>
          <ProgressBox status={this.props.status} />
        </Box>
        {this.props.status === 'finished' && (
          <Box sx={{ marginBottom: '100px' }}>
            <ActionButtons
              report={this.props.readyToCopy}
              conversationID={this.props.conversationID}
            />
          </Box>
        )}
        <Popper
          id={id}
          open={this.state.openPopper}
          anchorEl={this.state.anchorEl}
          style={{
            left: this.state.position.x + 10 + 'px',
            top: this.state.position.y + 15 + 'px',
            position: 'absolute'
          }}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper>
                <HighlightAltIcon onClick={this.openPromptForm} style={{ cursor: 'pointer' }} />
              </Paper>
            </Fade>
          )}
        </Popper>
        <Box ref={this.div}>
          {this.state.openPrompt && (
            <DetailPromptDialog
              stateChanger={this.openPromptForm}
              acceptPrompt={this.acceptPrompt}
              open={this.state.openPrompt}
              selectedText={this.state.exact_match}
              start_word={this.state.selectionStart}
              end_word={this.state.selectionEnd}
              report={plainReport.toString()}
              conversationID={this.props.conversationID}
            />
          )}
        </Box>
      </Box>
    );
  }
}

export default RenderReport;
