import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import mammoth from 'mammoth';
import { v4 as uuid } from 'uuid';
import { useDropzone } from 'react-dropzone';
import { Alert, Box, Button } from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { save_report_to_database } from '../utils';

const FileUpload = () => {
  const navigate = useNavigate();
  const [fileContent, setFileContent] = useState('');
  const [fileContentError, setFileContentError] = useState('');
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: {
      'text/*': ['.docx'],
      'image/*': []
    },
    onDrop: (files) => {
      // Handle file drop
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          mammoth
            .convertToHtml({ arrayBuffer: event.target.result })
            .then((result) => {
              setFileContent(result.value);
            })
            .catch((err) => setFileContentError('Error converting DOCX to HTML:', err));
        };

        reader.readAsArrayBuffer(file); // or reader.readAsDataURL(file) for images
      });
    },
    noClick: false
  });

  const processUploadFile = () => {
    const conversation_id = uuid();
    save_report_to_database(fileContent, conversation_id, 'manually uploaded file', 1);
    setTimeout(() => {
      navigate(`/${conversation_id}`);
    }, 500);
  };

  const removeUploadFile = (file) => {
    const newFiles = [...files];
    acceptedFiles.splice(file, 1);
  };

  const files = acceptedFiles.map((file, i) => (
    <li className="accepted-file" key={file.path}>
      {file.path} <DisabledByDefaultIcon onclick={removeUploadFile} />
    </li>
  ));

  return (
    <Box className="dnd-box" sx={{}}>
      <Box sx={{ width: '400px' }} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {acceptedFiles.length === 0 && (
          <Box sx={{ padding: '2vh' }}>Drag 'n' drop some files here, or click to select files</Box>
        )}
        {fileContentError && (
          <Alert sx={{ mb: 2 }} severity="error">
            {fileContentError}
          </Alert>
        )}
        {acceptedFiles.length > 0 && (
          <Box>
            <h4>Accepted File:</h4>
            <ul>{files}</ul>
          </Box>
        )}
      </Box>
      {acceptedFiles.length > 0 && (
        <Button
          sx={{ mt: 2, backgroundColor: '#00889a' }}
          onClick={processUploadFile}
          size="large"
          variant="contained"
        >
          Process Upload File
        </Button>
      )}
    </Box>
  );
};

export default FileUpload;
