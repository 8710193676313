import { useState, useEffect, Fragment, SetStateAction } from 'react';
import { v4 as uuid } from 'uuid';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Alert,
  Box,
  Container,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import data from '../data.json';

import companyLogo from '../images/DIPLO-logo.png';
import { Button, Typography } from '@mui/material';
import FileUpload from './FileUpload';

export interface Dataset {
  code_name: string;
  data_order: string;
  dataset_name: string;
  date_created: string;
  id: number;
  source_id: number;
}
export default function LandingPage() {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [report_type, setReportType] = useState<string>('research_report');
  const [task, setTask] = useState<string>('');
  const [datasets, setDatasets] = useState<Dataset[]>([]);
  const [selectedDatasets, setSelectedDatasets] = useState<Dataset[]>([]);
  const [error, setError] = useState<any>(null);
  const [uploadButton, setUploadButton] = useState<boolean>(false);

  const conversation_id = uuid();

  const handleTask = (event: { target: { value: SetStateAction<string> } }) => {
    if (!event.target.value.length) {
      setTask('');
      setError('You should insert term for researching');
    } else {
      setTask(event.target.value);
      setError(null);
    }
  };

  const fetchDatasets = async () => {
    try {
      const response = await fetch('https://datasets-api.diplomacy.edu/api/public/datasets');
      const result = await response.json();
      setDatasets(result);
    } catch (error) {
      console.error('Error fetching datasets:', error);
    }
  };

  useEffect(() => {
    localStorage.setItem('task', task);
    localStorage.setItem('report_type', report_type);
    fetchDatasets();
  }, [task, report_type]);

  const handleChange = (event: SelectChangeEvent) => {
    localStorage.setItem('report_type', event.target.value);
    setReportType(event.target.value);
  };

  const handleDatasets = (data: any) => {
    if (!data.length) {
      setError('No selected dataset, select one or more datasets');
      setSelectedDatasets([]);
    } else {
      setSelectedDatasets(data);
      localStorage.setItem('datasets', JSON.stringify(data));
      setError(null);
    }
  };

  const getErrors = (): boolean | undefined => {
    switch (true) {
      case !selectedDatasets || !selectedDatasets.length:
        setError('No selected dataset, select one or more datasets');
        return !selectedDatasets || !selectedDatasets.length;
        break;
      case !task || !task.length:
        setError('You should insert term for researching');
        return !task || !task.length;
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    if (getErrors()) return;
    window.open(`${'/' + conversation_id}`, '_blank', 'noopener');
  };

  const handleUploadButton = () => {
    setUploadButton(!uploadButton);
  };

  const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2)
    }
  }));

  return (
    <Fragment>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <img src={companyLogo} alt="DIPLO logo" width={240} />
        <h1>Research Assistant</h1>
        <Typography>
          Ask any question regarding Diplomacy, and we will help you with the research.
        </Typography>
        {!showForm && (
          <Root sx={{ textAlign: 'center' }}>
            <Button
              onClick={() => setShowForm(true)}
              sx={{ mt: 5, backgroundColor: '#00889a' }}
              size="large"
              variant="contained"
            >
              Star Research
            </Button>
            <Divider>OR</Divider>
            {!uploadButton ? (
              <Button onClick={handleUploadButton}>Upload File</Button>
            ) : (
              <FileUpload />
            )}
          </Root>
        )}
        {showForm && (
          <Box sx={{ mt: 6, display: 'flex', flexDirection: 'column' }}>
            {error && (
              <Alert sx={{ mb: 2 }} severity="error">
                {error}
              </Alert>
            )}
            <TextField
              required
              id="filled-required"
              onChange={handleTask}
              sx={{ width: '70vw' }}
              label="What would you like me to research next?"
            />
            <FormControl sx={{ mt: 2, alignItems: 'center', justifyContent: 'center' }}>
              <InputLabel id="report-type-select-helper-label">
                What type of report would you like me to generate?
              </InputLabel>
              <Select
                sx={{ width: '70vw' }}
                labelId="report-type-select-helper-label"
                id="report-type-select-helper"
                value={report_type}
                label="What type of report would you like me to generate?"
                onChange={handleChange}
              >
                {data &&
                  data['report_type'].map((dt) => (
                    <MenuItem key={dt.name} value={dt.name}>
                      {dt.value}
                    </MenuItem>
                  ))}
              </Select>
              <Autocomplete
                sx={{ width: '70vw', mt: 2 }}
                multiple
                id="tags-outlined"
                options={datasets}
                filterSelectedOptions
                getOptionLabel={(option) => option.dataset_name}
                onChange={(_event: any, newValue: any) => {
                  handleDatasets(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select datasets from list"
                    placeholder="Select Datasets"
                  />
                )}
              />
              <Button
                size="large"
                variant="contained"
                sx={{ marginTop: 5, backgroundColor: '#00889a' }}
                onClick={() => handleClick()}
              >
                Research
              </Button>
            </FormControl>
          </Box>
        )}
      </Container>
    </Fragment>
  );
}
