const { REACT_APP_DEV_AI_API_URL } = process.env;

export const createPlainReport = (report) => {
  let plainReport = [];
  report.map((rep) => plainReport.push(remove_html_tags(rep)));
  return plainReport;
};

export const remove_html_tags = (data, remove_new_line = true) => {
  if (remove_new_line) {
    return data.replace(/(<([^>]+)>)/gi, '').replace(/[\n\r]+/g, ' ');
  } else {
    return data.replace(/(<([^>]+)>)/gi, '');
  }
};

export const remove_anchor_tags = (data) => {
  return data.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>(.*?)<\/a>/gi, '$3');
};

export const split_string_by = (data, separator = '-----------------') => {
  return data.split(separator);
};

export const save_report_to_database = (
  toBeReplaced,
  conversation_id,
  question,
  manual_upload = 0
) => {
  return fetch(`${REACT_APP_DEV_AI_API_URL}/api/conversation/save`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      conversation_id: conversation_id,
      report_text: manual_upload ? toBeReplaced : toBeReplaced.join('-----------------'),
      question: question
    })
  })
    .then((res) => res.json())
    .then((res) => console.log(res));
};
