import React, { useEffect, useRef, Fragment, useState, SetStateAction } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { InputAdornment, TextField, Box, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CircularProgress from '@mui/material/CircularProgress';
import AdjustOutput from './AdjustOutput';
import { remove_html_tags } from '../utils';

const { REACT_APP_DEV_AI_API_URL } = process.env;

export default function DetailPromptDialog(props: any) {
  const [detailPromptResponse, setDetailPromptResponse] = useState<any>([]);
  const [prompt, setPrompt] = useState<string>('');
  const [adjustOutput, setAdjustOutput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePromptRequest = () => {
    setLoading(true);
    axios({
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
      url: `${REACT_APP_DEV_AI_API_URL}/api/research/detail-report-prompting${window.location.pathname}`,
      data: {
        conversation_id: props.conversationID,
        start: props.start_word,
        end: props.end_word,
        comment: prompt,
        highlight: props.selectedText.trim(),
        ai_text: '',
        whole_text: props.report
      }
    })
      .catch((error: any) => {
        console.log(error);
      })
      .then((response: any) => {
        setDetailPromptResponse((prevPrompt: any) => [
          ...prevPrompt,
          {
            conversation_id: response.data.conversation_id,
            start: response.data.start,
            end: response.data.end,
            highlight: remove_html_tags(response.data.highlight),
            comment: response.data.comment,
            ai_text: response.data.ai_text,
            whole_text: response.data.whole_text
          }
        ]);
      })
      .finally(() => {
        setLoading(false); // stop spinner (in response/error)
      });
  };

  const declineResponse = () => {
    const responseValue: any = detailPromptResponse.filter(
      (t1: any) => t1.exactMatch !== props.selectedText
    );

    setDetailPromptResponse(responseValue);
  };

  const handlePrompt = (event: { target: { value: SetStateAction<string> } }) => {
    setPrompt(event.target.value);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);

  const adjustOutputClick = () => {
    setAdjustOutput(!adjustOutput);
  };

  const saveManualAdjustment = (adjustment: string) => {
    const responseValue: any = detailPromptResponse.filter(
      (t1: any) => t1.highlight.trim() === props.selectedText.trim()
    );

    responseValue[0].ai_text = adjustment;

    setDetailPromptResponse(responseValue);

    adjustOutputClick();
  };

  return (
    <Fragment>
      <Dialog
        open={props.open}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{props.selectedText}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {detailPromptResponse &&
              detailPromptResponse.filter((t1: any) => t1.exactMatch === props.selectedText)
                .length !== 1 && (
                <TextField
                  multiline
                  fullWidth
                  id="detail-prompt-input"
                  onChange={handlePrompt}
                  value={prompt !== '' ? props.prompt : prompt}
                  label={props.prompt === '' ? 'Enter Prompt' : ''}
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment onClick={() => handlePromptRequest()} position="end">
                        <SendIcon sx={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            {loading && (
              <CircularProgress
                size={50}
                sx={{
                  color: '#00889a',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
            {detailPromptResponse.length > 0 && (
              <Box>
                <h3>Review prompt output</h3>
                {adjustOutput ? (
                  <AdjustOutput
                    saveManualAdjustment={saveManualAdjustment}
                    stateChanger={adjustOutputClick}
                    adjustOutout={adjustOutput}
                    text={detailPromptResponse[0].ai_text}
                  />
                ) : (
                  <Typography>{detailPromptResponse[0].ai_text}</Typography>
                )}
                {!adjustOutput && (
                  <Box display="flex" justifyContent={'space-between'} sx={{ mt: 5 }}>
                    <Button onClick={adjustOutputClick}>
                      <EditNoteIcon /> Adjust output manually
                    </Button>
                    <Box>
                      <Button
                        onClick={() =>
                          props.acceptPrompt(
                            props.selectedText,
                            detailPromptResponse[0].ai_text,
                            prompt
                          )
                        }
                      >
                        Accept
                      </Button>
                      <Button onClick={declineResponse}>Decline</Button>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        {!adjustOutput && (
          <DialogActions>
            <Button onClick={props.stateChanger}>Cancel</Button>
          </DialogActions>
        )}
      </Dialog>
    </Fragment>
  );
}
